.login-page {
  height: 100vh;
  background-color: #252525;
}

.login-form {
  min-height: 300px;
  color: #f4efe9 !important;
  background-color: #262a2d;
  width: 28vw;
  margin-top: -5vh;
  border: 1px solid #4f5255;
  border-radius: 1rem;
  padding-top: 4vh;
}
.login-form input {
  background-color: #252525 !important;
  border: 1px solid #4f5255 !important;
}
.error-message {
  margin: 2vh;
  text-align: center;
}

#show-password-div {
  padding-left: 0.75vh;
  padding-bottom: 0.25vh;
}

#login-button {
  margin: 2vh;
  width: 11vw;
}

.version-txt{
  color: #4f5255; 
  margin-top: -20px;
  font-size: 14px !important;
}

.site-center-text{
  text-align: center !important;
  color: #4f5255; 
  margin-top: 0px;
  font-size: 14px !important;
}
