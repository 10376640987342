/* .links-container {
  position: relative;
  font-size: 1.1rem;
} */

.navbar-brand {
  /* top: 3px; */
  position: relative;
}

.navbar-omni {
  /* background-color: #dadada; */
  color: #f4efe9 !important;
}

.seperator {
  /* background-color: #b4b4b4; */
  background-color: #515151;
  height: 50px;
  width: 0.5px;
}

.links-container a {
  color: #999999 !important;
  font-weight: 400;
}

.links-container a.active {
  color: #f4efe9 !important;
  font-weight: 400;
}

.user-info span {
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
  background-color: rgb(0, 0, 0);
}

.dropdown-item {
  color: #f4efe9 !important;
}

.dropdown-menu a:hover {
  background-color: rgb(41, 41, 41) !important;
}

.dropdown-item span {
  font-size: 21px;
  padding-right: 5px;
  padding-top: 2px;
  position: relative;
  top: 5px;
}

.omni-logo {
  width: 220px;
  height: 50px;
  margin-right: 35px !important;
  background: url('/images/dashboard_logo_header.svg') no-repeat center;
}

.notification-container {
  z-index: 9999 !important;
  white-space: nowrap !important;
  /* margin-right: 37% !important; */
}

.navbar-nav {
  padding-left: 20px !important;
}

.danger-submit-btn,
.danger-submit-btn:active {
  border-color: #de684f !important;
  background-color: #de684f !important;
}

.danger-submit-btn:hover {
  border-color: #de684f !important;
  background-color: #de684f !important;
  color: black !important;
}

.modal-body {
  font-size: 0.9vw !important;
  font-weight: 500 !important;
}

.modal-body-viewer {
  text-align: left !important;
}

.modal-body-logout {
  text-align: center !important;
}

.modal-body-alert {
  text-align: center !important;
}
