.status-icon {
  position: relative;
  /* padding-right: 10px; */
  height: 3.2vh;
}

.primary-name-edit-icon,
.output-settings-icon,
.guid-copy-icon {
  cursor: pointer;
}

.info-header,
.info-header .material-icons {
  font-size: 1rem;
  /* padding-left: 0.5vh; */
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  /* font-weight: 450; */
  color: #f4efe9 !important;
}

.material-icons:hover {color: #f4efe9 !important;}
.material-icons:active {color: #f4efe9 !important;}