.control-buttons button {
	/* padding: 0.5rem; */
}
.control-buttons .col {
	padding-left: 0.1rem;
}

.dropdown-item span {
	margin-left: 3px;
	margin-right: 3px;
	font-size: 15px;
}

.dropdown-menu-no-padding{
	padding: 0;
}

.hr {
	background:transparent;
	border-bottom:1px solid gainsboro;
  }
