.tab-pointer{
    /* border-bottom: 1px solid #3f4347 !important; */
    border: none !important;
    background-color: #272727;
    color: #f4efe9;
    cursor: pointer;
}

.none_active_tab{
    /* border: none !important; */
    border-bottom: solid 1px #454d55 !important;
    background-color: #252525 !important;
    color: #f4efe9 !important;
}

.none_active_tab:hover{border-bottom: solid 1px #454d55 !important;}
.none_active_tab:active{border-bottom: solid 1px #454d55 !important;}

.nav-item{
    border: none !important;
    /* background-color: #252525 !important; */
    color: #f4efe9 !important; 
}

/* .nav-item :hover{
    border: none !important;
} */

.nav-item .active{
    border: none !important;
    /* background-color: #373737 !important; */
    color: #f4efe9 !important;
}

.tab-activate .active{
    border: none !important;
    background-color: #373737 !important;
    color: #f4efe9 !important;
}

/* .tab-activate :hover{
} */

.alerts-bg{
    background-color: #1d1e20 !important;
    color: #f4efe9;
    overflow: hidden !important;
}

.bitrate-chart-and-devices-info{padding-right: 14px !important;}

/* remove scroll bar from devices info table */
.devices-info-scrollbar ::-webkit-scrollbar{
    display: none !important;
    /* background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 15px; */
}

/* custom scroller for logs devices info scrollbar */
/* .devices-info-scrollbar ::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-button {background-color: #373737; }
.devices-info-scrollbar ::-webkit-scrollbar-track {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-track-piece {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.devices-info-scrollbar ::-webkit-scrollbar-corner { background-color: #646464;}
.devices-info-scrollbar ::-webkit-resizer {background-color: #666;} */
