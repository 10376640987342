.chart-buttons-section {
    position: absolute;
    left: calc(50% - 100px);
}

.chart-button-container {
    padding: 5px;
    
}

.chart-providers {
    display: flex;
    justify-content: center;
    align-items: center;
}

svg > g > g:last-child { pointer-events: none }

svg {border-top: solid 1px #454d55 !important;}