.thumbnail-img-input {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #2e2e2e url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-input-extended {
	/* padding: 10px; */
	--thumbnailWidth: 350px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #2e2e2e url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-output {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16.1);
	/* background: #2e2e2e url("/images/video_label-white-24dp.svg") no-repeat center; */
	/* background-size: 100px auto; */
    background: #2e2e2e url("/images/screen_saver.gif") no-repeat center;	
	background-size: 95% auto;
}

/* .mini-preview{
	position: relative;
} */
