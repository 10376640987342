/* 
Omnistream logo dark blue: #457cbc
Omnistream logo light blue: #5cc2ee
font color 	#f4efe9

*/
/* ------------------------------------------------------------------
                              Color Guide

Basic blue:                   #457cbc
Lighter (10%) blue:           #6996c9
More lighter (30%) blue:      #b4cae4
Even more lighter (40%) blue: #dae5f1
Darker (10%) blue:            #366396

Grey 1 (80% lighter black):   ##d1d1d1
Grey 2 (40% lighter black):   #666666
Grey 3:                       #7d8891
Grey 4:                       #495057

App background color:			#f2f2f2
------------------------------------------------------------------- */

/* ------------------------------------------------------------------
                  Bootstrat default color overwriting
------------------------------------------------------------------- */
.bg-info, .btn-info, .badge-info {
	background-color: #DE684F !important;
	border-color: #3e3e3e !important;
	/* font-size: 1rem; */
	color: white;
}

/* not must */
/* .badge{
	background-color: #DE684F;
	border: none !important;
} */

.btn-white {
	color: white;
}

.btn-white:hover {
	color: rgb(255, 255, 255);
}

.btn-black {
	color: black;
}

.btn-black:hover {
	color: rgb(255, 255, 255);
}

.btn-background {
	background-color: #373737;
	color: white;
}

.btn-background:hover {
	background-color: #373737;
	color: #de684f;
}

.btn-general {
	background-color: transparent;
	color: #DE684F;
	border: none;
	outline: 0 !important;
	outline-style: none;
  }

  .btn-general-disabled {
	background-color: transparent;
	color: #f4efe9;
	border: none;
	outline: 0 !important;
	outline-style: none;
  }

  .temp-cool {
	background-color: #0078FF !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-warn {
	background-color: #ffc107!important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-critical {
	background-color: red !important;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.temp-background {
	background-color: #7d8891;
	color: white;
	border-color: #d1d1d1 !important;
	font-size: 0.7rem;
}

.text-info {
/*	color: #585858 !important; */
        color: #f4efe9 !important;

}



.btn-info.disabled,
.btn-info:disabled {
	background-color: #7d8891 !important;
	/* border-color: #d1d1d1 !important; */
}

.btn-info:hover {
	color: #ffffff !important;
	/* border-color: #d1d1d1 !important; */
}

.table .thead-dark th {
	background-color: #373737 !important;
	padding-top: 15px;
	padding-bottom: 15px;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: #d1d1d1 !important;
	background-color: #DE684F !important;
}

input{
	background-color: #272a2c !important;
	border: none !important;
}

input:focus{
	background-color: #272a2c !important;
	border: none !important;
}

a {
	color: #cacaca;
	font-weight: 400;
}

a:hover {
	color: #b1dffd;
}

.tooltip-inner {
	background-color: #DE684F;
}

/* ------------------------------------------------------------------
                           Global styles
------------------------------------------------------------------- */

.app {
	/* cursor: none; */
	height: 100vh;
	background-color: #2a2a2a;
}

.data-area {
    background-color: #1d1e20 !important;
    color: #f4efe9;
	/* background-color: #1f2022; */
	height: 40vh;
	border: 0.75px solid #3e403f;
	border-radius: 0 0.35rem 0.35rem 0.35rem;
	overflow-y: auto;
}

.card{
	border: none !important;
}
.card-header {
	background-color: #7d8891;
	border: 0px solid #3e403f;
	padding: 0.15rem 0;
}
.card-header:first-child{
	border: none !important;
	background-color: #373737;
}

.card-header-disabled {
	background-color: #dadada;
	padding: 0.15rem 0;
	pointer-events:none
}

.card-body {
	background-color: #121212;
	padding: 0.4rem 1.25rem;
}

.tooltip-inner {
	border: 1.5px solid #212529;
}

.alert {
	margin-bottom: 0 !important;
}

.click-item {
	cursor: pointer;
}
button:focus,button:hover{
    outline:none;
}

.text-font {
	font-size: small;
}

.center-icon {
	font-size: 1.1rem;
	margin-right: 3px;
	margin-top:4px;
	display: block !important;
	pointer-events: none;
	cursor: default;
}

.modal-dialog, .modal-content{
	  color: #f4efe9 !important;
      background-color: #252525 !important;
	  border: 1px solid #3e403f !important;
	  border-radius: 10px;
	  /* filter: invert(1) !important; */
}

.close {
	color: #f4efe9 !important;	
}

.modal-body{
	border: none !important;
}

.parent-class {
	position: relative;
  }
.login-page {
  height: 100vh;
  background-color: #252525;
}

.login-form {
  min-height: 300px;
  color: #f4efe9 !important;
  background-color: #262a2d;
  width: 28vw;
  margin-top: -5vh;
  border: 1px solid #4f5255;
  border-radius: 1rem;
  padding-top: 4vh;
}
.login-form input {
  background-color: #252525 !important;
  border: 1px solid #4f5255 !important;
}
.error-message {
  margin: 2vh;
  text-align: center;
}

#show-password-div {
  padding-left: 0.75vh;
  padding-bottom: 0.25vh;
}

#login-button {
  margin: 2vh;
  width: 11vw;
}

.version-txt{
  color: #4f5255; 
  margin-top: -20px;
  font-size: 14px !important;
}

.site-center-text{
  text-align: center !important;
  color: #4f5255; 
  margin-top: 0px;
  font-size: 14px !important;
}

.form-group {
	margin: 0.7rem;
}

.form-control{
	color: #f4efe9 !important;
	border-radius: 5px !important;
	height: 35px !important;
}

.spinner-border-sm {
	width: 1.5em;
	height: 1.5em;
}

#notification {
	display: none;
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
	background-color: #343a40;
	border: 1px solid #2c2f31 !important;
	right: 25% !important;
}

#close_btn{
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;

}
#close_btn:hover{
	color: rgb(255, 255, 255) !important;
	font-weight: 500 !important;
}
/* .links-container {
  position: relative;
  font-size: 1.1rem;
} */

.navbar-brand {
  /* top: 3px; */
  position: relative;
}

.navbar-omni {
  /* background-color: #dadada; */
  color: #f4efe9 !important;
}

.seperator {
  /* background-color: #b4b4b4; */
  background-color: #515151;
  height: 50px;
  width: 0.5px;
}

.links-container a {
  color: #999999 !important;
  font-weight: 400;
}

.links-container a.active {
  color: #f4efe9 !important;
  font-weight: 400;
}

.user-info span {
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
  background-color: rgb(0, 0, 0);
}

.dropdown-item {
  color: #f4efe9 !important;
}

.dropdown-menu a:hover {
  background-color: rgb(41, 41, 41) !important;
}

.dropdown-item span {
  font-size: 21px;
  padding-right: 5px;
  padding-top: 2px;
  position: relative;
  top: 5px;
}

.omni-logo {
  width: 220px;
  height: 50px;
  margin-right: 35px !important;
  background: url('/images/dashboard_logo_header.svg') no-repeat center;
}

.notification-container {
  z-index: 9999 !important;
  white-space: nowrap !important;
  /* margin-right: 37% !important; */
}

.navbar-nav {
  padding-left: 20px !important;
}

.danger-submit-btn,
.danger-submit-btn:active {
  border-color: #de684f !important;
  background-color: #de684f !important;
}

.danger-submit-btn:hover {
  border-color: #de684f !important;
  background-color: #de684f !important;
  color: black !important;
}

.modal-body {
  font-size: 0.9vw !important;
  font-weight: 500 !important;
}

.modal-body-viewer {
  text-align: left !important;
}

.modal-body-logout {
  text-align: center !important;
}

.modal-body-alert {
  text-align: center !important;
}

.sliding-panel {
  box-shadow: 0 -4px 4px -4px #a5afb9;
  background-color: #252525;
  width: 99.8vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.sliding-panel__hide {
  -webkit-transform: translateY(92vh);
          transform: translateY(92vh);
}

.sliding-panel__show {
  -webkit-transform: translateY(42vh);
          transform: translateY(42vh);
  overflow: hidden;
}

.sliding-panel__show_simple {
  -webkit-transform: translateY(50vh);
          transform: translateY(50vh);
  overflow: hidden;
}

.layout-block-center,
.layout-block-left,
.layout-block-right {
  background-color: #252525;
}

.layout-block-center {
  width: 100%;
}

.control-panel {
  height: 8vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.dashboard {
  margin: 1vh
}

.danger-submit-btn, 
.danger-submit-btn:active{
  border-color: #DE684F !important;
  background-color: #DE684F !important;
}

.danger-submit-btn:hover{
  border-color: #DE684F !important;
  background-color: #DE684F !important;
  color: black !important;
}

.multi-viewer {
	height: 42vh;
	overflow-y: scroll;
	overflow-x: hidden;
	color: #f4efe9;
}

/* remove scroll bar from window */
.mviewer-scrollbar::-webkit-scrollbar{
	display: none !important;
	/* background-color: #373737 !important; 
    overflow: auto !important;
    height: auto !important;
    width: 20px; */ 
}

/* custom scroller for multiviewer */
/* .mviewer-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.mviewer-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.mviewer-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.mviewer-scrollbar::-webkit-resizer {background-color: #666;} */

.multi-viewer-spinner {
	height: 37vh;
}

.broadcast-details-spinner {
	height: 20vh;
}

.outputs-list-spinner {
	height: 20vh;
}

.settings-control-spinner {
	height: 30vh;
}
.devices-info-spinner {
	height: 35vh;
}

.viewer {
	-webkit-flex-direction: column;
	        flex-direction: column;
    display: -webkit-flex;
    display: flex;
	margin-left: 0.5vw;
	margin-right: 1vw;
	margin-bottom: 1vh !important;
	background-color: #373737;
	/* border: 1px solid #373737; */
	border-radius: 5px !important;
}

/* .drag-and-drop-container {
	
} */

.broadcast-info {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 250px;
	-webkit-transform: translateY(-120%);
	        transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}

.broadcast-info-extended {
	position: absolute;
	/* top: 65%; */
	/* left: 50%; */
	width: 350px;
	-webkit-transform: translateY(-120%);
	        transform: translateY(-120%);
	color: #f2f2f2;
	text-shadow: 0 0 5px black;
}

.broadcast-info-extended > span {
	display: inline-block;
	width: 50%;
	text-align: center;
}

.status-icon {
  position: relative;
  /* padding-right: 10px; */
  height: 3.2vh;
}

.primary-name-edit-icon,
.output-settings-icon,
.guid-copy-icon {
  cursor: pointer;
}

.info-header,
.info-header .material-icons {
  font-size: 1rem;
  /* padding-left: 0.5vh; */
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  /* font-weight: 450; */
  color: #f4efe9 !important;
}

.material-icons:hover {color: #f4efe9 !important;}
.material-icons:active {color: #f4efe9 !important;}
.alarm-icon-container-absolute {
  position: absolute;
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon-container-relative {
  /* position: relative; */
  padding: 3px;
  right: 0px;
  /* padding: 5px; */
}

.alarm-icon {
  padding-right: 0.3rem;
}

.alarm-icon-tooltip-down .tooltip-message {
  top: 120%;
}

.alarm-icon-tooltip-up .tooltip-message {
  bottom: 125%;
}

.tooltip-message {
  visibility: hidden;
  position: absolute;
  left: -315%;
  z-index: 3;
  width: 203px;
  border: 1.5px solid #f4efe9;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  background-color: #de684f;
  color: #f4efe9;
}

/* -------------- Tooltip arrow styles start -------------- */
.alarm-icon-tooltip-down .tooltip-message::after {
  content: ' ';
  position: absolute;
  bottom: 95%;
  margin-left: 70px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #de684f transparent;
}

.alarm-icon-tooltip-down .tooltip-message::before {
  content: ' ';
  position: absolute;
  bottom: 102%;
  right: 14px;
  margin-left: -5px;
  border-width: 0 8px 12px 8px;
  border-style: solid;
  border-color: transparent transparent #f4efe9 transparent;
}

.alarm-icon-tooltip-up .tooltip-message::after {
  top: 100%;
  border-color: #f4efe9 transparent transparent transparent;
  border-width: 7px !important;
}

.tooltip-message::after {
  position: absolute;
  left: 50%;
  margin-left: -2.5%;
  content: ' ';
  /* border-width: 0.5rem; */
  border-style: solid;
}
/* -------------- Tooltip arrow styles end -------------- */

.show-tooltip {
  visibility: visible;
}

.thumbnail-img-input {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #2e2e2e url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-input-extended {
	/* padding: 10px; */
	--thumbnailWidth: 350px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background: #2e2e2e url("/images/videocam-white-24dp.svg") no-repeat center;
	background-size: 100px auto;
}

.thumbnail-img-output {
	/* padding: 10px; */
	--thumbnailWidth: 250px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16.1);
	/* background: #2e2e2e url("/images/video_label-white-24dp.svg") no-repeat center; */
	/* background-size: 100px auto; */
    background: #2e2e2e url("/images/screen_saver.gif") no-repeat center;	
	background-size: 95% auto;
}

/* .mini-preview{
	position: relative;
} */

.critical-issue-container {
	font-size: 1.8em;
	font-weight: 700;
}

.critical-issue-container-icon {
	font-size: 2.7em;
}

.outputs-list {
  color: #f4efe9;
  /* height: 50vh; */
  border-top: 1px solid #515151;
  overflow-y: scroll;
}

.outputs-scrollbar::-webkit-scrollbar{
  display: none !important;
   /*background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 20px;*/ 
}

/* custom scroller for outputs */
/* .outputs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.outputs-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.outputs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.outputs-scrollbar::-webkit-resizer {background-color: #666;} */
.output {
	display: none;
	margin-left: 0.5vw;
	margin-right: 1vw;
	margin-bottom: 1vh !important;
	background-color: #373737;
	border: 1px solid #3e3e3e;
}

.critical-alarm-thumbnail {
	--thumbnailWidth: 203px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background-color: #353b41;
	cursor: pointer;
}

.critical-alarm-icon {
	font-size: 7em;
}

.warning-alarm-icon-container {
	bottom: 20%;
	position: absolute;
	left: 85%;
	text-shadow: 0 0 5px black;
}

.broadcast-details {
	padding-top: 15px;
	background-color: #252525;
}

.control-buttons-custom-style {
	
	background: #f2f2f2;
}

.details-container {
	width: 76vw;
}

.alarms-container {
	width: 54vh;
	padding-left: 1rem;
}


.broadcast-settings ul,
.broadcast-settings .data-area {
	margin-left: 0.5vw;
	
}

#settings-сontrol {
	width: 9.6vw;
}

#rtmp,
#output-association {
	width: 6vw;

}

#outputs-save-btn {
	margin-top: 3vh;
	width: 8vw;
}

.broadcast-settings-padding{
	padding-right: 0px !important;
}
.tab-pointer {
  cursor: pointer;
  background-color: #272727;
}

.btn-icon {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.icon-pending-alarms {
  color: #dc3545;
}

.icon-pending-alerts {
  color: #ffc107 !important;
}

.is-exist-export {
  height: 8px;
  width: 8px;
  background-color: #10fc27;
  border-radius: 100%;
  position: absolute;
  margin-top: -1px;
  margin-left: -1px;
}

/* custom scroller for records scrollbar */
.records-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #373737;
}
.records-scrollbar::-webkit-scrollbar-button {
  background-color: #373737;
}
.records-scrollbar::-webkit-scrollbar-track {
  background-color: #373737;
}
.records-scrollbar::-webkit-scrollbar-track-piece {
  background-color: #373737;
}
.records-scrollbar::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
.records-scrollbar::-webkit-scrollbar-corner {
  background-color: #646464;
}
.records-scrollbar::-webkit-resizer {
  background-color: #666;
}


.card-link {
	background-color: #373737;
	color: #f4efe9;
	display: inline-block;
	width: 100%;
	height: 100%;
}

#settings-apply-btn,
#settings-revert-btn {
	/* width: 6vw; */
}

.settings-control {
	display:block; 
	overflow: hidden;
	border: none !important;
}

.icon-size {
	vertical-align: -7%;
	padding-right: 2px;
	font-size: small;
}

.drawer-text {
	margin: 0 auto;
	color:#f4efe9;
}

.arrow-icon {
	color: #f4efe9;
}


.form-inline {
	margin-bottom: 0.3rem;
	margin-left: 1vw;
}

.select-input label {
	font-size: small;
	width: 30%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

select{
	background-color: #272a2c !important;
	color: white !important;
	border: none !important;
}

.select-input select {
	font-size: small;
	width: 50% !important;
}

.output-association-select-input select {
	height: 2.3rem;
}

.alerts-filter-select-input {
	margin-left: 0;
}
.alerts-filter-select-input label {
	width: 15%;
}
.alerts-filter-select-input select {
	width: 85% !important;
}

.alert-delete {
	margin-left: 3px;
	margin-right: 3px;
}


.carousel-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.carousel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.carousel-arrow-icon {
  font-size: 30px;
}

.carousel-dots-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.carousel-dot-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}

.carousel-dot-active-icon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #de684f;
  transition: 0.5s;
}

.carousel-dot-mr {
  margin-right: 1rem;
}

.carousel-dot-mr:last-child {
  margin-right: 0px;
}

.export-list-item-container {
  padding-top: 2px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: 10rem;
  font-size: 0.8rem;
  height: 100%;
  overflow-y: auto;
}

.export-list-item-container::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-button {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-track {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-track-piece {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
.export-list-item-container::-webkit-scrollbar-corner {
  background-color: #646464;
}
.export-list-item-container::-webkit-resizer {
  background-color: #666;
}

.options-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #f4efe9 !important;
}

.export-container span {
  cursor: pointer;
  /* font-weight: 600;
	font-size: 1.5rem; */
}

.export-container {
  height: 100%;
}

.list-item-container {
  word-wrap: break-word;
}

.export-heading {
  color: #ffffff !important;
  padding-top: 3px !important;
  padding-bottom: 2px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.export-heading-text-container {
  display: -webkit-flex;
  display: flex;
}

.export-label-heading {
  color: #ffffff !important;
  padding-bottom: 2px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.edit-label-input-style {
  margin-left: 0px !important;
  padding-top: 10px !important;
}

.export-link {
  padding-left: 3px !important;
  color: #f3f3f3 !important;
  padding-bottom: 5px !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
}

/* .export-container span:hover {
	cursor: pointer;
	color: #b1dffd;
} */

.hide {
  display: none;
}

/* toggle button css */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #de684f;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #de684f;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-check svg {
  border-top: none !important;
}

.react-toggle-track-check,
.react-toggle-track-x svg {
  border-top: none !important;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  /* border: 1px solid #de684f; */
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #de684f;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #9e9e9e;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #9e9e9e;
}

.delete-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.delete-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.edit-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.edit-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.copy-btn-input-fields {
  color: #747474;
  vertical-align: middle !important;
}

.copy-btn-input-fields:hover {
  color: #d3d3d3 !important;
  vertical-align: middle !important;
}

.copy-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn-input {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.7;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input:hover {
  color: #d3d3d3 !important;
}

.copy-btn-input-disabled {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input-disabled:hover {
  color: rgb(105, 105, 105) !important;
}

.playlist_remove {
  display: -webkit-flex;
  display: flex;
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
  font-size: 30px;
}

.label-delete-btn {
  font-size: 20px;
}

.label-edit-btn {
  font-size: 20px;
}

.label-cancel-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-cancel-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-cancel-btn:hover {
  color: #ed6243 !important;
}

.label-save-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-save-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-save-btn:hover {
  color: #ed6243 !important;
}

.form-padd {
  display: grid;
}

.form-padd label {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.form-padd input {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.container-padd {
  margin-top: 4%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.container-padd::-webkit-scrollbar {
  /* chrome based */
  width: 0px;
  background: transparent;
}

.arrow-btn {
  position: static !important;
  position: initial !important;
  vertical-align: -15% !important;
}
.form-check-inline {
  display: inline-block !important;
  margin: 2px !important;
}

.button-srt {
  cursor: pointer !important;
  background-color: #c0c0c0 !important;
  color: #3e423f;
}

.button-rtmp {
  cursor: pointer !important;
  background-color: white !important;
}

.button-omniplayer {
  cursor: pointer !important;
  background-color: #de684f !important;
}

.label-container {
  margin: 0px !important;
  padding: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.label-container-text {
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
}

.label-static-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 2% !important;
  font-weight: 500 !important;
}

.export_btns_padd {
  padding-left: 2px !important;
}

.copy_btn_pad {
  margin-left: 10px !important;
}

.label-icon-size {
  font-size: 24px !important;
  padding-left: 1% !important;
}

hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #d8d8d865,
    #adadad,
    #d8d8d865,
    rgba(255, 255, 255, 0)
  );
  border: 0;
  height: 1px;
}

.hr-label {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #d8d8d865,
    #adadad,
    #d8d8d865,
    rgba(255, 255, 255, 0)
  );
  border: 0;
  height: 1px;
}

.label-buttons-paddings {
  padding-right: 20px !important;
}

.label-txt-dsc {
  color: #f3f3f3 !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
  margin-left: -2px !important;
  text-transform: capitalize;
}

.export-label-btns {
  vertical-align: sub !important;
  display: inline-block !important;
  font-size: 22px !important;
  background-color: #646464;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  background: transparent;
}

.buttons-edit-label {
  padding-top: 8px !important;
  margin-bottom: 0px !important;
}

.edit-label-input-style {
  display: -webkit-flex !important;
  display: flex !important;
  padding-left: 0px !important;
}

.edit-label-buttons-style {
  display: -webkit-flex !important;
  display: flex !important;
  padding-left: 5px !important;
}

.edit-text-input {
  margin-left: -5px !important;
  width: 262px !important;
}

.export-heading-container {
  display: -webkit-flex;
  display: flex;
}

#host.form-control {
  font-size: 100% !important;
}

#port.form-control {
  font-size: 100% !important;
}

#key.form-control {
  font-size: 100% !important;
}

.export-list-option {
  height: 100%;
  overflow: hidden;
}

/* #create-export {
  width: 100% !important;
  transform: translateX(5%) !important;
} */

.export-list-option-item {
  width: 250px !important;
  height: 48px;
  background: #ffffff;
  border-radius: 10px;
  text-transform: uppercase;
}

.show-export-list {
  position: static;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: #de684f;
  color: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border-radius: 0;
  border-top: 0.75px solid #3e403f;
}

.show-export-list:hover {
  color: white;
}

.show-export-list-text {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.5rem;
}

.show-export-list-text span {
  margin-right: 0.5rem;
}

.show-export-list-text-bold {
  font-weight: 600 !important;
  letter-spacing: .03rem;
}

.export-list-item-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.list-bottom-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #373737;
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}

.list-bottom-buttons-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.list-bottom-buttons {
  width: 100px;
  height: 35px;
  margin: 0.5rem !important;
  background-color: #de684f;
  color: white;
  border-radius: 5px;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.list-bottom-buttons:hover {
  color: white;
}

.list-bottom-buttons-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.list-bottom-buttons-icons {
  font-size: large;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.export-image-container {
  margin-right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.export-image-container img {
  width: 22px !important;
  height: 22px !important;
  border-radius: 5px !important;
}

.export-heading b {
  text-transform: uppercase;
}

.label-buttons-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: calc(1.5em + 0.5rem + 2px);
}

.copy-btn-input-export {
  margin-left: 0px !important;
  line-height: 1.7;
  font-size: 20px;
}

.btn-input-export-container {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
}

.export-list-option-light-active {
  width: 14px;
  height: 14px;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #10fc27; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #10fc27, #17bb28);
}

.export-list-option-light-disable {
  width: 14px;
  height: 14px;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #ed6243; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #ed6243, #a8412a);
}

.export-list-option-text-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 22px;
}

.export-list-option-text {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #000000 !important;
}

.text-omniplayer {
  color: white !important;
}

.mi-1 {
  font-size: 20px;
}

.react-toggle-track {
  width: 43px;
  height: 22px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 24px;
}

.react-toggle-thumb {
  width: 20px;
  height: 20px;
}

.react-toggle-track-check svg {
  margin-left: -0.15rem;
}

.react-toggle-track-x svg {
  margin-left: 0.15rem;
}

.playlist_remove_icon {
  font-size: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.playlist_remove_button {
  width: 50px;
  padding: 2px;
}

.my-tooltip {
  position: relative;
  display: inline-block;
}

.my-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background: #252525;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;
  width: 150px;
  bottom: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  z-index: 1;
}

.my-tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  display: inline-block;
  position: relative;
  border: 2px solid #4d4d4d;
  text-decoration: none;
  border-radius: 2px;
  padding: 20px;
  margin-top: 50px;
}

.my-tooltip .tooltip-text::after {
  content: ' ';
  position: absolute;
  top: 95%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #252525 transparent transparent transparent;
}

.my-tooltip .tooltip-text::before {
  content: ' ';
  position: absolute;
  top: 110%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #4d4d4d transparent transparent transparent;
}

.copy-embed-icon {
  display: -webkit-flex;
  display: flex;
}

.play-video-icon {
  display: -webkit-flex;
  display: flex;
}

.SRTCaller-input-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.SRTCaller-input {
  width: 50%;
}

.SRTCaller-input:nth-child(1) {
  padding-right: 4px;
}

.SRTCaller-input:nth-child(2) {
  padding-left: 4px;
}

.input-custom {
  padding-right: 2rem !important;
}

.input-custom-key {
  padding-right: 3.5rem !important;
}

.remove_cursor{
  cursor: not-allowed !important;
}

@media screen and (min-width: 1750px) {
  .list-bottom-buttons-container {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-right: 66px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (min-height: 1000px) {
  .show-export-list {
    position: absolute;
  }
  .options-container {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .container-padd {
    margin-top: 0;
  }
}

.records {
    height: 34vh;
}

.record-info {
    width: 100%;
}

/* .record-text {
    /* margin-bottom: 0.5rem; 
} */

.records-control-spinner {
    height: 34vh;
}

.arc-wrapper {
    position: relative;
}

.arc {
    position: absolute;
    border-radius: 100%;
    width: 2.4em;
    height: 2.4em;
}

.arc_start {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border: 4px solid;
}

.percentage {
    text-align: center;
    width: 2.4rem;
    height: 2.4rem;
    padding-top: 0.65rem;
    font-size: 0.7rem;
    color: #f4efe9;
}


.record-title {
    font-weight: bold;
    font-size: 1.2em;
}

.record-container {
    border: 1px solid #353638;
    border-radius: 0.3rem;
    padding: 0.5rem;
    margin: 0.3rem;
    background-color: #373737;
}

#delete-record-btn, #download-record-btn, #pause-record,
#record-uploaded, #play-record {
    height: 4.5vh;
    margin-right: 1vh;
}

#record-uploaded {
    padding: 0.7rem;
}

.progress-wrapper {
    width: 60%;
    height: 2.4rem;
}

.action-wrapper {
    width: 40%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.row-wrapper {
    width: 100%;
}
.chart-buttons-section {
    position: absolute;
    left: calc(50% - 100px);
}

.chart-button-container {
    padding: 5px;
    
}

.chart-providers {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

svg > g > g:last-child { pointer-events: none }

svg {border-top: solid 1px #454d55 !important;}
.chart-buttons-section {
  position: absolute;
  left: calc(50% - 100px);
}

.chart-button-container {
  padding: 5px;
}

.chart-canvas-border {
  border-top: solid 1px #454d55 !important;
}

.chart-legends-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 40px;
  font-size: 14px;
  text-align: center !important;
}

.chat-circle {
  background: #456bd9;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.chart-legends-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10px;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  #chart-canvas {
    max-height: 175px;
  }
}
@media (min-width: 1700px) {
  #chart-canvas {
    max-height: 280px;
  }
}

.devices-info {
	height: 100%;
	overflow-y: scroll;
}

.device-name-column {
	font-size: small;
	width: 14%;
}

.battery-column {
	font-size: small;
	width: 10%;
}
.temperature-column {
	font-size: small;
	width: 0.1%;
}
.provider-column {
	font-size: small;
	width: 10%;
}
.network-type-column {
	font-size: small;
	width: 4%;
}
.signal-strength-column {
	font-size: small;
	width: 10%;
}

.table th,
.table td {
	padding: 0.6rem; 
	border: 0.75px solid #353638;
}
.lower-rows th,
.lower-rows td {
	padding: 0.3rem 0.6rem;
}


.devices-table-scroll {
	overflow-y: scroll;
}

[class*="signal-bars-"] {
	opacity: 0;
}

.signal-bars-1 {
	line-height: 0.3rem;
}

.signal-bars-2 {
	line-height: 0.8rem;
}

.signal-bars-3 {
	line-height: 1.3rem;
}

.signal-bars-4 {
	line-height: 1.8rem;
}

.progress { 
	height: 10px;
}

.txt-color{
	color: #f4efe9;
}
.tab-pointer{
    /* border-bottom: 1px solid #3f4347 !important; */
    border: none !important;
    background-color: #272727;
    color: #f4efe9;
    cursor: pointer;
}

.none_active_tab{
    /* border: none !important; */
    border-bottom: solid 1px #454d55 !important;
    background-color: #252525 !important;
    color: #f4efe9 !important;
}

.none_active_tab:hover{border-bottom: solid 1px #454d55 !important;}
.none_active_tab:active{border-bottom: solid 1px #454d55 !important;}

.nav-item{
    border: none !important;
    /* background-color: #252525 !important; */
    color: #f4efe9 !important; 
}

/* .nav-item :hover{
    border: none !important;
} */

.nav-item .active{
    border: none !important;
    /* background-color: #373737 !important; */
    color: #f4efe9 !important;
}

.tab-activate .active{
    border: none !important;
    background-color: #373737 !important;
    color: #f4efe9 !important;
}

/* .tab-activate :hover{
} */

.alerts-bg{
    background-color: #1d1e20 !important;
    color: #f4efe9;
    overflow: hidden !important;
}

.bitrate-chart-and-devices-info{padding-right: 14px !important;}

/* remove scroll bar from devices info table */
.devices-info-scrollbar ::-webkit-scrollbar{
    display: none !important;
    /* background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 15px; */
}

/* custom scroller for logs devices info scrollbar */
/* .devices-info-scrollbar ::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-button {background-color: #373737; }
.devices-info-scrollbar ::-webkit-scrollbar-track {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-track-piece {background-color: #373737;}
.devices-info-scrollbar ::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.devices-info-scrollbar ::-webkit-scrollbar-corner { background-color: #646464;}
.devices-info-scrollbar ::-webkit-resizer {background-color: #666;} */

.alarms-and-alerts .data-area {
	height: 40vh;
	padding: 0.3rem;
}

.alarms-and-alerts-padding {padding-left: 0px !important;}
.alarms-section ul {
	padding-left: 0;
	list-style-type: none;
}

.close, .ml-2{
	color:#ffffff !important;
}

.alert-size{
    font-size: 1rem;
}

.alert-text{
    font-size: 0.8rem;
}
.logs-section {
	position: relative;
	-webkit-flex-direction: column;
	        flex-direction: column;
	display: -webkit-flex;
	display: flex;
	height: 34vh;
	width: 100%;
	overflow-y: scroll;
	border: 0.75px solid #3e403f;
	border-radius: 0.35rem;
}

.logs-section ul {
	margin-top: 22px;
	/* margin-block-end: 5px; */
	width: 100%;
	position: absolute;
	padding-left: 0.4vw;
	list-style-type: none;
}

.clear-btn {
	color: #f4efe9 !important;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: inline-block;
}

.clear-btn:focus {
	outline:none;
	box-shadow: none;
  }

  /* .clear-btn:hover {
	color: #b1dffd;
  } */

/* custom scroller for logs scrollbar */
.logs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.logs-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.logs-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.logs-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.logs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.logs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.logs-scrollbar::-webkit-resizer {background-color: #666;}

#displayed-name-input {
	/* background-color: red !important; */
	display: inline-block;
	width: 71%;
	margin-left: 7px;
	text-transform: capitalize !important;
}

.modal-footer {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.dialog-button {
  min-width: 100px;
  background-color: #de684f !important;
  border-color: #3e3e3e !important;
  color: white;
}

.dialog-button-edit-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.dialog-button-edit {
  width: 100%;
}

.header-container {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.modal-footer {
  text-align: center !important;
  display: inline-block !important;
}

.edit-container {
  margin-bottom: 20px;
}

.dialog-pad-top {
  padding-top: 25px !important;
  padding-bottom: 15px !important;
}

.dialog-pad-bottom {
  padding-top: 15px !important;
  padding-bottom: 25px !important;
}

.modal-title {
  text-align: center !important;
}

#first-modal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.modal-footer-fields-container {
  margin-right: 0px !important;
  text-align: start;
}

.modal-footer-buttons-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: 0px !important;
}

.modal-footer-buttons-container button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-field-icon-star {
  margin-left: 2px;
  margin-bottom: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-end;
          align-content: flex-end;
  font-size: 30px;
  color: #de684f;
}

.modal-field-label {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.modal-field-label-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.modal-field-label-required {
  margin-top: -10px;
}

.cap-letter {
  text-transform: capitalize !important;
}

.modal-body {
  white-space: nowrap !important;
}

.modal-content-video {
  height: 450px;
  width: 770px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #3e403f;
}

.modal-dialog-video {
  height: 450px;
  width: 770px;
  max-width: 770px;
  background: transparent !important;
  border: none !important;
}

.close-modal-btn-default {
  position: absolute;
  height: 26px;
  top: -10px;
  right: 0;
  bottom: 0;
  background: transparent;
  color: white;
  border: none;
  font-size: 26px !important;
}

.modal-media-icons-container {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.modal-media-icons-container svg {
  border-top: none !important;
}

.livePlayer-text-container {
  margin-bottom: 10px;
  width: 770px;
  height: 26px;
  display: -webkit-flex;
  display: flex;
}

.livePlayer-text {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  text-transform: uppercase;
}

.modal_media_container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 45px;
  padding-right: 45px;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.modal_media_container svg {
  border: none !important;
}

.copy_icon_rounded:hover{
  cursor: pointer !important;
}

.control-buttons button {
	/* padding: 0.5rem; */
}
.control-buttons .col {
	padding-left: 0.1rem;
}

.dropdown-item span {
	margin-left: 3px;
	margin-right: 3px;
	font-size: 15px;
}

.dropdown-menu-no-padding{
	padding: 0;
}

.hr {
	background:transparent;
	border-bottom:1px solid gainsboro;
  }

.broadcast-details-toggle-btn {
    margin: 5px;
}

.center-in-layout {
    position: absolute;
    left: calc(50% - 100px);
}
#map-container {
	width: 100%;
	height: 89vh;
}

.map-cards-section {
	position: fixed;
	left: 1.3vw;
	top: 12vh;
	z-index: 2;
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
	.map-cards-section {
		top: 23vh;
	}
}

.map-marker-container {
	width: 10rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;

	-webkit-align-items: center;

	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-size: 1rem;
	font-weight: bold;
}

.marker-image-container {
	margin-left: 12%;
}

.map-card {
	opacity: 0.95 !important;
	width: 13vw;
	margin-bottom: 1.5vh;
}

.card-map-color{background-color: #373737 !important; 	padding-bottom: 10px !important;}
.card-map-color button{background-color: #de684f !important;border: none !important;}
.card-map-color button:hover{color: #f4efe9 !important;border: none !important; }
.card-map-color button:active{border: none !important;}

.card-link{color: #f4efe9 !important;}

.bg-info-border{background-color: #373737 !important;}
