.output {
	display: none;
	margin-left: 0.5vw;
	margin-right: 1vw;
	margin-bottom: 1vh !important;
	background-color: #373737;
	border: 1px solid #3e3e3e;
}

.critical-alarm-thumbnail {
	--thumbnailWidth: 203px;
	width: var(--thumbnailWidth);
	height: calc(var(--thumbnailWidth) * 9 / 16);
	background-color: #353b41;
	cursor: pointer;
}

.critical-alarm-icon {
	font-size: 7em;
}

.warning-alarm-icon-container {
	bottom: 20%;
	position: absolute;
	left: 85%;
	text-shadow: 0 0 5px black;
}
