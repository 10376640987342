.sliding-panel {
  box-shadow: 0 -4px 4px -4px #a5afb9;
  background-color: #252525;
  width: 99.8vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: transform 0.6s ease;
}

.sliding-panel__hide {
  transform: translateY(92vh);
}

.sliding-panel__show {
  transform: translateY(42vh);
  overflow: hidden;
}

.sliding-panel__show_simple {
  transform: translateY(50vh);
  overflow: hidden;
}

.layout-block-center,
.layout-block-left,
.layout-block-right {
  background-color: #252525;
}

.layout-block-center {
  width: 100%;
}

.control-panel {
  height: 8vh;
  display: flex;
  align-items: center;
}

.dashboard {
  margin: 1vh
}

.danger-submit-btn, 
.danger-submit-btn:active{
  border-color: #DE684F !important;
  background-color: #DE684F !important;
}

.danger-submit-btn:hover{
  border-color: #DE684F !important;
  background-color: #DE684F !important;
  color: black !important;
}
