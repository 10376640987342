.export-list-item-container {
  padding-top: 2px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: 10rem;
  font-size: 0.8rem;
  height: 100%;
  overflow-y: auto;
}

.export-list-item-container::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-button {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-track {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-track-piece {
  background-color: #373737;
}
.export-list-item-container::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
.export-list-item-container::-webkit-scrollbar-corner {
  background-color: #646464;
}
.export-list-item-container::-webkit-resizer {
  background-color: #666;
}

.options-container {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #f4efe9 !important;
}

.export-container span {
  cursor: pointer;
  /* font-weight: 600;
	font-size: 1.5rem; */
}

.export-container {
  height: 100%;
}

.list-item-container {
  word-wrap: break-word;
}

.export-heading {
  color: #ffffff !important;
  padding-top: 3px !important;
  padding-bottom: 2px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-heading-text-container {
  display: flex;
}

.export-label-heading {
  color: #ffffff !important;
  padding-bottom: 2px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.edit-label-input-style {
  margin-left: 0px !important;
  padding-top: 10px !important;
}

.export-link {
  padding-left: 3px !important;
  color: #f3f3f3 !important;
  padding-bottom: 5px !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
}

/* .export-container span:hover {
	cursor: pointer;
	color: #b1dffd;
} */

.hide {
  display: none;
}

/* toggle button css */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #de684f;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #de684f;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-check svg {
  border-top: none !important;
}

.react-toggle-track-check,
.react-toggle-track-x svg {
  border-top: none !important;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  /* border: 1px solid #de684f; */
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #de684f;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #9e9e9e;
  -moz-box-shadow: 0px 0px 3px 2px #9e9e9e;
  box-shadow: 0px 0px 2px 3px #9e9e9e;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #9e9e9e;
  -moz-box-shadow: 0px 0px 5px 5px #9e9e9e;
  box-shadow: 0px 0px 5px 5px #9e9e9e;
}

.delete-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.delete-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.edit-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.edit-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.copy-btn-input-fields {
  color: #747474;
  vertical-align: middle !important;
}

.copy-btn-input-fields:hover {
  color: #d3d3d3 !important;
  vertical-align: middle !important;
}

.copy-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.copy-btn-input {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.7;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input:hover {
  color: #d3d3d3 !important;
}

.copy-btn-input-disabled {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
  margin-top: -1.7%;
  color: rgb(105, 105, 105) !important;
  font-size: 20px;
}

.copy-btn-input-disabled:hover {
  color: rgb(105, 105, 105) !important;
}

.playlist_remove {
  display: flex;
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
  font-size: 30px;
}

.label-delete-btn {
  font-size: 20px;
}

.label-edit-btn {
  font-size: 20px;
}

.label-cancel-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-cancel-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-cancel-btn:hover {
  color: #ed6243 !important;
}

.label-save-btn {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
  padding-top: 0px;
}

.label-save-btn:disabled {
  color: #f4efe9;
  border: none;
  outline: 0 !important;
  outline-style: none;
  background-color: transparent !important;
}

.label-save-btn:hover {
  color: #ed6243 !important;
}

.form-padd {
  display: grid;
}

.form-padd label {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.form-padd input {
  margin-top: -1.7%;
  padding-top: 0px !important;
  display: inline-block !important;
}

.container-padd {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.container-padd::-webkit-scrollbar {
  /* chrome based */
  width: 0px;
  background: transparent;
}

.arrow-btn {
  position: initial !important;
  vertical-align: -15% !important;
}
.form-check-inline {
  display: inline-block !important;
  margin: 2px !important;
}

.button-srt {
  cursor: pointer !important;
  background-color: #c0c0c0 !important;
  color: #3e423f;
}

.button-rtmp {
  cursor: pointer !important;
  background-color: white !important;
}

.button-omniplayer {
  cursor: pointer !important;
  background-color: #de684f !important;
}

.label-container {
  margin: 0px !important;
  padding: 0px !important;
  display: flex !important;
}

.label-container-text {
  display: flex;
  font-size: 16px;
}

.label-static-text {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 2% !important;
  font-weight: 500 !important;
}

.export_btns_padd {
  padding-left: 2px !important;
}

.copy_btn_pad {
  margin-left: 10px !important;
}

.label-icon-size {
  font-size: 24px !important;
  padding-left: 1% !important;
}

hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #d8d8d865,
    #adadad,
    #d8d8d865,
    rgba(255, 255, 255, 0)
  );
  border: 0;
  height: 1px;
}

.hr-label {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #d8d8d865,
    #adadad,
    #d8d8d865,
    rgba(255, 255, 255, 0)
  );
  border: 0;
  height: 1px;
}

.label-buttons-paddings {
  padding-right: 20px !important;
}

.label-txt-dsc {
  color: #f3f3f3 !important;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
  margin-left: -2px !important;
  text-transform: capitalize;
}

.export-label-btns {
  vertical-align: sub !important;
  display: inline-block !important;
  font-size: 22px !important;
  background-color: #646464;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  background: transparent;
}

.buttons-edit-label {
  padding-top: 8px !important;
  margin-bottom: 0px !important;
}

.edit-label-input-style {
  display: flex !important;
  padding-left: 0px !important;
}

.edit-label-buttons-style {
  display: flex !important;
  padding-left: 5px !important;
}

.edit-text-input {
  margin-left: -5px !important;
  width: 262px !important;
}

.export-heading-container {
  display: flex;
}

#host.form-control {
  font-size: 100% !important;
}

#port.form-control {
  font-size: 100% !important;
}

#key.form-control {
  font-size: 100% !important;
}

.export-list-option {
  height: 100%;
  overflow: hidden;
}

/* #create-export {
  width: 100% !important;
  transform: translateX(5%) !important;
} */

.export-list-option-item {
  width: 250px !important;
  height: 48px;
  background: #ffffff;
  border-radius: 10px;
  text-transform: uppercase;
}

.show-export-list {
  position: static;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: #de684f;
  color: white;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border-radius: 0;
  border-top: 0.75px solid #3e403f;
}

.show-export-list:hover {
  color: white;
}

.show-export-list-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.show-export-list-text span {
  margin-right: 0.5rem;
}

.show-export-list-text-bold {
  font-weight: 600 !important;
  letter-spacing: .03rem;
}

.export-list-item-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-bottom-container {
  display: flex;
  justify-content: center;
  background-color: #373737;
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}

.list-bottom-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.list-bottom-buttons {
  width: 100px;
  height: 35px;
  margin: 0.5rem !important;
  background-color: #de684f;
  color: white;
  border-radius: 5px;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.list-bottom-buttons:hover {
  color: white;
}

.list-bottom-buttons-text {
  display: flex;
  justify-content: center;
}

.list-bottom-buttons-icons {
  font-size: large;
  display: flex;
  align-items: center;
}

.export-image-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.export-image-container img {
  width: 22px !important;
  height: 22px !important;
  border-radius: 5px !important;
}

.export-heading b {
  text-transform: uppercase;
}

.label-buttons-container {
  display: flex;
  align-items: center;
  height: calc(1.5em + 0.5rem + 2px);
}

.copy-btn-input-export {
  margin-left: 0px !important;
  line-height: 1.7;
  font-size: 20px;
}

.btn-input-export-container {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
}

.export-list-option-light-active {
  width: 14px;
  height: 14px;
  flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #10fc27; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #10fc27, #17bb28);
}

.export-list-option-light-disable {
  width: 14px;
  height: 14px;
  flex: 0 1 auto;
  margin-left: auto;
  /* background-color: #ed6243; */
  border-radius: 100%;
  background: radial-gradient(circle at 50% 50%, #ed6243, #a8412a);
}

.export-list-option-text-container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 22px;
}

.export-list-option-text {
  flex: 0 1 auto;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #000000 !important;
}

.text-omniplayer {
  color: white !important;
}

.mi-1 {
  font-size: 20px;
}

.react-toggle-track {
  width: 43px;
  height: 22px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 24px;
}

.react-toggle-thumb {
  width: 20px;
  height: 20px;
}

.react-toggle-track-check svg {
  margin-left: -0.15rem;
}

.react-toggle-track-x svg {
  margin-left: 0.15rem;
}

.playlist_remove_icon {
  font-size: 30px;
  display: flex;
  justify-content: flex-end;
}

.playlist_remove_button {
  width: 50px;
  padding: 2px;
}

.my-tooltip {
  position: relative;
  display: inline-block;
}

.my-tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background: #252525;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;
  width: 150px;
  bottom: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  z-index: 1;
}

.my-tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  display: inline-block;
  position: relative;
  border: 2px solid #4d4d4d;
  text-decoration: none;
  border-radius: 2px;
  padding: 20px;
  margin-top: 50px;
}

.my-tooltip .tooltip-text::after {
  content: ' ';
  position: absolute;
  top: 95%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #252525 transparent transparent transparent;
}

.my-tooltip .tooltip-text::before {
  content: ' ';
  position: absolute;
  top: 110%;
  right: 80%;
  margin-left: -5px;
  border-width: 12px 8px 0 8px;
  border-style: solid;
  border-color: #4d4d4d transparent transparent transparent;
}

.copy-embed-icon {
  display: flex;
}

.play-video-icon {
  display: flex;
}

.SRTCaller-input-container {
  display: flex;
  width: 100%;
}

.SRTCaller-input {
  width: 50%;
}

.SRTCaller-input:nth-child(1) {
  padding-right: 4px;
}

.SRTCaller-input:nth-child(2) {
  padding-left: 4px;
}

.input-custom {
  padding-right: 2rem !important;
}

.input-custom-key {
  padding-right: 3.5rem !important;
}

.remove_cursor{
  cursor: not-allowed !important;
}

@media screen and (min-width: 1750px) {
  .list-bottom-buttons-container {
    flex: 1 1 0px;
    margin-right: 66px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-height: 1000px) {
  .show-export-list {
    position: absolute;
  }
  .options-container {
    justify-content: center;
  }
  .container-padd {
    margin-top: 0;
  }
}
