.multi-viewer {
	height: 42vh;
	overflow-y: scroll;
	overflow-x: hidden;
	color: #f4efe9;
}

/* remove scroll bar from window */
.mviewer-scrollbar::-webkit-scrollbar{
	display: none !important;
	/* background-color: #373737 !important; 
    overflow: auto !important;
    height: auto !important;
    width: 20px; */ 
}

/* custom scroller for multiviewer */
/* .mviewer-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.mviewer-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.mviewer-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.mviewer-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.mviewer-scrollbar::-webkit-resizer {background-color: #666;} */
