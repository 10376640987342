.chart-buttons-section {
  position: absolute;
  left: calc(50% - 100px);
}

.chart-button-container {
  padding: 5px;
}

.chart-canvas-border {
  border-top: solid 1px #454d55 !important;
}

.chart-legends-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 40px;
  font-size: 14px;
  text-align: center !important;
}

.chat-circle {
  background: #456bd9;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.chart-legends-content {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  #chart-canvas {
    max-height: 175px;
  }
}
@media (min-width: 1700px) {
  #chart-canvas {
    max-height: 280px;
  }
}
