.outputs-list {
  color: #f4efe9;
  /* height: 50vh; */
  border-top: 1px solid #515151;
  overflow-y: scroll;
}

.outputs-scrollbar::-webkit-scrollbar{
  display: none !important;
   /*background-color: #373737 !important;  
    overflow: auto !important;
    height: auto !important;
    width: 20px;*/ 
}

/* custom scroller for outputs */
/* .outputs-scrollbar::-webkit-scrollbar {width: 8px; height: 3px; background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-button {background-color: #373737; }
.outputs-scrollbar::-webkit-scrollbar-track {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-track-piece {background-color: #373737;}
.outputs-scrollbar::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.outputs-scrollbar::-webkit-scrollbar-corner { background-color: #646464;}
.outputs-scrollbar::-webkit-resizer {background-color: #666;} */