.modal-footer {
  justify-content: space-evenly;
}

.dialog-button {
  min-width: 100px;
  background-color: #de684f !important;
  border-color: #3e3e3e !important;
  color: white;
}

.dialog-button-edit-container {
  display: flex;
  flex-direction: row;
}

.dialog-button-edit {
  width: 100%;
}

.header-container {
  flex-direction: column;
  align-items: center;
}

.modal-footer {
  text-align: center !important;
  display: inline-block !important;
}

.edit-container {
  margin-bottom: 20px;
}

.dialog-pad-top {
  padding-top: 25px !important;
  padding-bottom: 15px !important;
}

.dialog-pad-bottom {
  padding-top: 15px !important;
  padding-bottom: 25px !important;
}

.modal-title {
  text-align: center !important;
}

#first-modal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.modal-footer-fields-container {
  margin-right: 0px !important;
  text-align: start;
}

.modal-footer-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 0px !important;
}

.modal-footer-buttons-container button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-field-icon-star {
  margin-left: 2px;
  margin-bottom: -10px;
  display: flex;
  align-content: flex-end;
  font-size: 30px;
  color: #de684f;
}

.modal-field-label {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.modal-field-label-container {
  display: flex !important;
  align-items: flex-end;
}

.modal-field-label-required {
  margin-top: -10px;
}

.cap-letter {
  text-transform: capitalize !important;
}

.modal-body {
  white-space: nowrap !important;
}

.modal-content-video {
  height: 450px;
  width: 770px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e403f;
}

.modal-dialog-video {
  height: 450px;
  width: 770px;
  max-width: 770px;
  background: transparent !important;
  border: none !important;
}

.close-modal-btn-default {
  position: absolute;
  height: 26px;
  top: -10px;
  right: 0;
  bottom: 0;
  background: transparent;
  color: white;
  border: none;
  font-size: 26px !important;
}

.modal-media-icons-container {
  height: 60px;
  display: flex;
  justify-content: space-around;
}

.modal-media-icons-container svg {
  border-top: none !important;
}

.livePlayer-text-container {
  margin-bottom: 10px;
  width: 770px;
  height: 26px;
  display: flex;
}

.livePlayer-text {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
}

.modal_media_container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 45px;
  padding-right: 45px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.modal_media_container svg {
  border: none !important;
}

.copy_icon_rounded:hover{
  cursor: pointer !important;
}
